var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[(_vm.versionForm)?_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Data Version'),"label-for":"dataVersion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"dataVersion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dataVersion","state":errors.length > 0 ? false:null},model:{value:(_vm.versionForm.dataVersion),callback:function ($$v) {_vm.$set(_vm.versionForm, "dataVersion", $$v)},expression:"versionForm.dataVersion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2075262431)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Farmer & HGs Version'),"label-for":"farmerAndHGsVersion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"farmerAndHGsVersion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"farmerAndHGsVersion","state":errors.length > 0 ? false:null},model:{value:(_vm.versionForm.farmerAndHGsVersion),callback:function ($$v) {_vm.$set(_vm.versionForm, "farmerAndHGsVersion", $$v)},expression:"versionForm.farmerAndHGsVersion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,288277727)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Modern Break Down Version'),"label-for":"modernBreakDownVersion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"modernBreakDownVersion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"modernBreakDownVersion","state":errors.length > 0 ? false:null},model:{value:(_vm.versionForm.modernBreakDownVersion),callback:function ($$v) {_vm.$set(_vm.versionForm, "modernBreakDownVersion", $$v)},expression:"versionForm.modernBreakDownVersion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3484737919)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Custom Ancestry Break Down Version'),"label-for":"customAncestryBreakDownVerion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"customAncestryBreakDownVerion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customAncestryBreakDownVerion","state":errors.length > 0 ? false:null},model:{value:(_vm.versionForm.customAncestryBreakDownVerion),callback:function ($$v) {_vm.$set(_vm.versionForm, "customAncestryBreakDownVerion", $$v)},expression:"versionForm.customAncestryBreakDownVerion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3646961823)})],1)],1)],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('save-button',{attrs:{"showSaveAndClose":false,"onClickSave":_vm.saveItem.bind(this, false)}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }