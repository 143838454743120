<template>
  <b-card>
      <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">

        <b-row>
          <b-col cols="12" md="12" lg="12" v-if="versionForm">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Data Version')" label-for="dataVersion">
                  <validation-provider #default="{ errors }" rules="required" name="dataVersion">
                    <b-form-input id="dataVersion" v-model="versionForm.dataVersion" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Farmer & HGs Version')" label-for="farmerAndHGsVersion">
                  <validation-provider #default="{ errors }" rules="required" name="farmerAndHGsVersion">
                    <b-form-input id="farmerAndHGsVersion" v-model="versionForm.farmerAndHGsVersion" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Modern Break Down Version')" label-for="modernBreakDownVersion">
                  <validation-provider #default="{ errors }" rules="required" name="modernBreakDownVersion">
                    <b-form-input id="modernBreakDownVersion" v-model="versionForm.modernBreakDownVersion" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Custom Ancestry Break Down Version')" label-for="customAncestryBreakDownVerion">
                  <validation-provider #default="{ errors }" rules="required" name="customAncestryBreakDownVerion">
                    <b-form-input id="customAncestryBreakDownVerion" v-model="versionForm.customAncestryBreakDownVerion" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <save-button 
              :showSaveAndClose="false"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

import ApiService from '@/common/api.service'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      versionForm: null,
    }
  },
  methods: {
    fetchItem() {
      ApiService.get('VersionControl', {}, this)
        .then(response => {
          this.versionForm = response.result;
        });
    },
    saveItem() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          ApiService.post('VersionControl', this.versionForm, this)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
              } else {
                AlertService.error(this, response.message)
              }
              this.fetchItem();
            });
        }
      })
    },
  },
  created() {
    this.fetchItem();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
</style>
